// @flow
import React, { useState, useEffect } from 'react';
import {
    Button,
    Grid,
    makeStyles,
    ButtonGroup,
    FormControlLabel,
    Checkbox,
    Card,
} from '@material-ui/core';
import {
    SALE_OF_BOOTS,
    SALE_OF_SOCKS_AND_INSOLES,
    productTypeCode,
} from 'constants/global';
import * as text from 'constants/strings';
import ViewContentCard from 'components/Layout/ViewContentCard';
import SaleSearchBar from 'components/SaleSearchBar';
import {
    validateNumberValue,
    isShopAllowedToShowShopFrom,
    findShopById,
} from 'helpers/global';
import {
    addAlert,
    setIsLoading,
    selectShops,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import SaleTableRow from 'components/SaleTableRow';
import { sendAuthAjax } from 'helpers/authorization';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { selectUser } from 'context/features/user/userSlice';
import { clearSearchParams } from 'context/features/sale/saleSlice';
import { AxiosError } from 'axios';
import type { Order, Shoe } from '../helpers/interface';
import store from '../context/store';
import SaleCurrencyValue from '../components/SaleCurrencyValue';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '10px 0px',
    },
    buttonGroup: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            flexDirection: 'column',
            '& button': {
                flex: 1,
            },
        },
    },
    currencyContainer: {
        maxWidth: 'calc(100vw - 49px)',
        padding: '10px 20px',
        marginBottom: '10px',
    },
    currencyValue: {
        fontWeight: 'bold',
    },
}));

const Sale = () => {
    const [viewType, setViewType] = useState(SALE_OF_BOOTS);
    const [result, setResult] = useState([]);
    const [isEcommerce, setIsEcommerce] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ReturnType<typeof store.getState>) =>
        selectUser(state)
    );
    const shops = useSelector((state) => selectShops(state));
    const [allowAddOrder, setAllowAddOrder] = useState(true);
    const [page, setPage] = useState(0);

    const handleEcommerceChange = (event: Event) => {
        setIsEcommerce(event.target.checked);
    };

    const getSocksInsoles = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'post',
                `/products/search/socks-insoles`
            );

            setResult(response.data);
        } catch (err) {
            const error: AxiosError = err;
            const errorText =
                error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRow = async (data: Order, productData: Shoe) => {
        if (!validateNumberValue(data.size)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }

        if (data.quantity <= 0 && viewType === SALE_OF_SOCKS_AND_INSOLES) {
            dispatch(
                addAlert({ content: 'Wybierz poprawną ilość', type: 'error' })
            );
            return;
        }

        if (!allowAddOrder) {
            return;
        }
        setAllowAddOrder(false);
        dispatch(setIsLoading(true));

        try {
            await sendAuthAjax('post', `/orders`, {
                ...data,
                shopId: user.shopId || data.shopFromId,
                productId: productData.id,
                ecommerce: isEcommerce,
                couponId: data?.coupon?.id ?? null
            });

            dispatch(
                addAlert({
                    content: 'Złożono zamówienie',
                    type: 'success',
                })
            );

            dispatch(clearSearchParams());
            setResult([]);

            if (viewType === SALE_OF_SOCKS_AND_INSOLES) {
                getSocksInsoles();
            }
        } catch (err) {
            const error: AxiosError = err;
            const errorText =
                error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
        setAllowAddOrder(true);
    };

    const onClearSearchParams = () => {
        setPage(0);

        if (viewType === SALE_OF_BOOTS) {
            setResult([]);
        } else if (viewType === SALE_OF_SOCKS_AND_INSOLES) {
            getSocksInsoles();
        }
    };

    const onSearchSuccess = (searchResult) => {
        setPage(0);
        setResult(searchResult);
    };

    const getHeaders = () => {
        const headers = [
            text.CODE,
            text.BRAND,
            text.COLOR,
            text.MODEL,
            text.NORMAL_PRICE,
            text.PROMOTIONAL_PRICE,
            text.PRICE_IN_CURRENCY,
            text.DISCOUNT,
            text.COUPON,
            text.PAYMENT,
        ];
        if (isShopAllowedToShowShopFrom(findShopById(shops, user.shopId))) {
            headers.push(text.FROM_SHOP);
        }
        headers.push(text.SIZE);
        if (viewType === SALE_OF_SOCKS_AND_INSOLES) {
            headers.push(text.QUANTITY);
        }
        headers.push('');

        return headers;
    };

    useEffect(() => {
        if (viewType === SALE_OF_SOCKS_AND_INSOLES) {
            getSocksInsoles();
        } else {
            setResult([]);
        }
    }, [viewType]);

    return (
        <>
            <SaleCurrencyValue /> 
            <ViewContentCard title={text[viewType]}>
                <Grid container className={classes.container}>
                    <ButtonGroup
                        className={classes.buttonGroup}
                        variant="contained"
                    >
                        <Button
                            onClick={() => setViewType(SALE_OF_BOOTS)}
                            color={`${
                                viewType === SALE_OF_BOOTS
                                    ? 'secondary'
                                    : 'primary'
                            }`}
                        >
                            Sprzedaż obuwia
                        </Button>
                        <Button
                            onClick={() => {
                                setViewType(SALE_OF_SOCKS_AND_INSOLES);
                                setResult([]);
                            }}
                            color={`${
                                viewType === SALE_OF_SOCKS_AND_INSOLES
                                    ? 'secondary'
                                    : 'primary'
                            }`}
                        >
                            Sprzedaż akcesoriów
                        </Button>
                    </ButtonGroup>
                </Grid>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isEcommerce}
                            onChange={handleEcommerceChange}
                        />
                    }
                    label={text.IS_IT_ECOMMERCE}
                />

                <SaleSearchBar
                    setResult={setResult}
                    productType={
                        viewType === SALE_OF_BOOTS
                            ? productTypeCode.shoe
                            : productTypeCode.shoeAccessories
                    }
                    onClearParams={onClearSearchParams}
                    onSearchSuccess={onSearchSuccess}
                />

                {result.length > 0 && (
                    <SaleTableWithPagination
                        headers={getHeaders()}
                        pageController={{
                            page,
                            setPage,
                        }}
                        rowRender={(row: Shoe) => (
                            <SaleTableRow
                                key={row.id}
                                rowData={row}
                                handleClickRow={handleClickRow}
                                viewType={viewType}
                                shopFromVisible={isShopAllowedToShowShopFrom(
                                    findShopById(shops, user.shopId)
                                )}
                                showCouponSelect
                            />
                        )}
                        rowsData={result}
                    />
                )}
            </ViewContentCard>
        </>
    );
};

export default Sale;
