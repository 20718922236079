import {
    Grid,
    IconButton,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GraphicWorkTimesBlock from 'components/Graphic/GraphicWorkTimesBlock';
import { selectShops } from 'context/features/global/globalSlice';
import { selectUser } from 'context/features/user/userSlice';
import { filterShopsWithoutEcommerce, findShopByName } from 'helpers/global';
import moment from 'moment';
import 'moment/locale/pl';
import { GraphicContext } from 'pages/Graphic';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { StyledTableCell } from 'styles/components';
import { map, sortBy } from 'lodash';
import type { Shop } from '../../helpers/interface';
import * as text from 'constants/strings';
import useIsGranted from 'hooks/useIsGranted';

export const ClickedTableCell = styled.div`
    background-color: #e1ebec;
    color: #fff;
    padding: 5px;
    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
    top: 2px;
    position: absolute;
    height: auto;
    right: 1px;
    left: 1px;
    bottom: 0px;
    background-color: ${({ bgcolor }) => bgcolor && bgcolor};
}
`;

const useStyles = makeStyles((theme) => ({
    plusBtn: {
        padding: '0px 0px',
    },
    workerBox: {
        minHeight: 24,
    },
    stickyCol: {
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    weekDay: {
        [theme.breakpoints.down('xs')]: {
            float: 'left',
            width: '240px',
            position: 'sticky',
            left: '0',
            zIndex: '1',
            background: '#fff',
        },
    },

    innerOne: {
        float: 'left',
    },
    innerTwo: {
        float: 'left',
        clear: 'left',
        fontSize: '10px',
    },
    loggedTime: {
        float: 'right',
        marginLeft: '5px',
    },
}));

const GraphicTable = () => {
    const {
        setStartDate,
        startDate,
        employeesData,
        handlePeriodDialogOpen,
        graphicTypes,
        editAccess,
        setSelectShop,
        selectShop,
        shopScheduleData,
    } = useContext(GraphicContext);
    const shops = useSelector((state) => selectShops(state));
    const [order, setOrder] = useState('asc');
    const [graphicSort, setGraphicSort] = useState(false);
    const classes = useStyles();
    const user = useSelector((state) => selectUser(state));
    const isGranted = useIsGranted();

    const canSeeScheduleOnGraphicTable = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_DIRECTOR_CODE,
    ]);

    const getCurrentPeriod = () => {
        const startOfWeek = moment(startDate).startOf('week');
        const endOfWeek = moment(startDate).endOf('week');

        const days = [];
        let day = startOfWeek;

        while (day <= endOfWeek) {
            days.push(day.toDate());
            day = day.clone().add(1, 'd');
        }

        return days;
    };

    const getUserTimeSheet = (interval, day) => {
        const timeSheet = interval.find(
            (int) =>
                moment(int.shiftDate).format('YYYY-MM-DD') ===
                moment(day).format('YYYY-MM-DD')
        );

        return timeSheet;
    };

    const getAttendanceScheduleForDay = (day) => {
        if(!shopScheduleData) {
            return null;
        }

        const schedule = shopScheduleData.schedules[moment(day).format('YYYY-MM-DD')];
        const scheduled = schedule?.scheduledEmployeeQuantity ?? 0;
        const used = schedule?.actuallyEmployeeQuantity ?? 0;

        return `${used} / ${scheduled}`;
    };

    const findGraphicTypeByValue = (graphicTypes, value) => {
        return graphicTypes.find((t) => t.value === value);
    };

    const getCellBgColor = (type = '') => {
        const shop = findShopByName(shops, type);

        const graphicType = findGraphicTypeByValue(graphicTypes, type);

        let color = '#e1ebec';

        if (shop !== undefined) {
            color = shop.color;
        }
        if (graphicType?.additional_data.color) {
            color = graphicType.additional_data.color;
        }

        return color;
    };

    const compare = (a, b) => {
        if (b.firstName < a.firstName) {
            return -1;
        }
        if (b.firstName > a.firstName) {
            return 1;
        }
        return 0;
    };

    const sort = (a, b) => {
        if (graphicSort === true) {
            if (order === 'desc') {
                return compare(a, b);
            }
            return -compare(a, b);
        }
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell
                            style={{
                                borderRight: '3px solid rgba(0, 0, 0, 0.27)',
                            }}
                            align="center"
                        >
                            <TableSortLabel
                                active={graphicSort}
                                direction={order}
                                onClick={() => {
                                    setGraphicSort(true);
                                    setOrder(order === 'asc' ? 'desc' : 'asc');
                                }}
                            >
                                Pracownik
                            </TableSortLabel>
                        </StyledTableCell>
                        <StyledTableCell
                            align="center"
                            colSpan="7"
                            className={classes.weekDay}
                        >
                            <IconButton
                                onClick={() => {
                                    setStartDate(
                                        moment(startDate).subtract(7, 'd')
                                    );
                                }}
                            >
                                <ArrowBackIosIcon color="primary" />
                            </IconButton>
                            {`${startDate.year()} tydzień ${startDate.week()} `}
                            <IconButton
                                onClick={() => {
                                    setStartDate(moment(startDate).add(7, 'd'));
                                }}
                            >
                                <ArrowForwardIosIcon color="primary" />
                            </IconButton>
                        </StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            style={{
                                borderRight: '3px solid rgba(0, 0, 0, 0.27)',
                                backgroundColor: '#f5f5f5',
                            }}
                        >
                            <Select
                                displayEmpty
                                value={selectShop}
                                onChange={(e) => setSelectShop(e.target.value)}
                            >
                                <MenuItem value="">Wszystko</MenuItem>
                                {filterShopsWithoutEcommerce(
                                    shops,
                                    (shop: Shop) => (
                                        <MenuItem key={shop.id} value={shop.id}>
                                            {shop.name}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </TableCell>
                        {map(getCurrentPeriod(), (day) => (
                            <StyledTableCell
                                align="center"
                                key={`head-${moment(day).format(
                                    'dddd, DD-MM'
                                )}`}
                            >
                                {moment(day).format('dddd, DD-MM')}
                                {canSeeScheduleOnGraphicTable && (
                                    <div>
                                        {getAttendanceScheduleForDay(day)}
                                    </div>
                                )}
                            </StyledTableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {map(sortBy(employeesData, sort), (row) => (
                        <TableRow key={row.employeeId}>
                            <TableCell
                                style={{
                                    borderRight:
                                        '3px solid rgba(0, 0, 0, 0.27)',
                                }}
                                component="th"
                                scope="row"
                                className={classes.stickyCol}
                            >
                                <Grid
                                    container
                                    justify="space-between"
                                    alignItems="center"
                                    className={classes.workerBox}
                                >
                                    {`${row.firstName} ${row.lastName}`}
                                    {editAccess && (
                                        <IconButton
                                            className={classes.plusBtn}
                                            onClick={() =>
                                                handlePeriodDialogOpen(
                                                    row.employeeId
                                                )
                                            }
                                        >
                                            <AddIcon color="primary" />
                                        </IconButton>
                                    )}
                                </Grid>
                            </TableCell>
                            {map(getCurrentPeriod(), (day) => {
                                const userTimeSheet = getUserTimeSheet(
                                    row.interval,
                                    moment(day).format('YYYY-MM-DD')
                                );

                                return (
                                    <TableCell
                                        style={{ position: 'relative' }}
                                        align="center"
                                        key={`body-${moment(day).format(
                                            'dddd, MM-DD'
                                        )}`}
                                    >
                                        <ClickedTableCell
                                            onClick={() =>
                                                handlePeriodDialogOpen(
                                                    row.employeeId,
                                                    day,
                                                    userTimeSheet,
                                                    row.name
                                                )
                                            }
                                            bgcolor={getCellBgColor(
                                                userTimeSheet?.type
                                            )}
                                        >
                                            <GraphicWorkTimesBlock
                                                userTimeSheet={userTimeSheet}
                                                employeeOriginShopName={
                                                    row.name
                                                }
                                            />
                                        </ClickedTableCell>
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default GraphicTable;
