import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Select,
    MenuItem,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Typography,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { sendAuthAjax } from 'helpers/authorization';
import * as text from 'constants/strings';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from 'context/features/user/userSlice';
import moment from 'moment';
import { selectUser } from 'context/features/user/userSlice';
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: '8.5rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '11rem',
        },
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tableHeader: (props) => ({
        backgroundColor: props.headerColor,
    }),
    shopSelect: {
        marginLeft: '1rem',
    },
    averageSaleContainer: {
        marginTop: '.5rem',
    },
}));

const TopFiveModels = () => {
    const dispatch = useDispatch();
    const [shoesData, setShoesData] = useState(null);
    const isGranted = useIsGranted();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const week = moment().subtract(7, 'd').week();
    const year = moment().subtract(7, 'd').year();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_LIDER_CODE,
    ]);
    const user = useSelector((state) => selectUser(state));
    const userShop = shops.find((shop) => shop?.id === user?.shopId);
    const [selectedShop, setSelectedShop] = useState(
        !hasPermissions ? userShop?.id : 'all'
    );

    const selectedShopData =
        selectedShop === 'all'
            ? { name: 'Wszystkie sklepy', color: '#ffffff' }
            : shops.find((shop) => shop?.id === selectedShop);

    const classes = useStyles({
        headerColor: selectedShopData?.color || '#ffffff',
    });

    const getWeeklyAverageSales = async (selectedShop) => {
        const shopRequest = selectedShop === 'all' ? '' : selectedShop;
        const url = `/top-five-models?week=${week}&year=${year}&shop=${shopRequest}`;
        try {
            const response = await sendAuthAjax('get', url);
            const weeklyAverageSales = response.data;
            setShoesData(weeklyAverageSales);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch(logoutUser());
            }
        }
    };

    const handleShopChange = (event) => {
        setSelectedShop(event.target.value);
    };

    useEffect(() => {
        getWeeklyAverageSales(selectedShop);
    }, [selectedShop]);

    return (
        <>
            {selectedShop && (
                <ViewContentCard
                    title={`${text.TOP_FIVE_LIST} - ${selectedShopData?.name}`}
                    selected-shop={selectedShop}
                >
                    {hasPermissions && (
                        <Select
                            value={selectedShop}
                            onChange={handleShopChange}
                            displayEmpty
                            className={classes.shopSelect}
                        >
                            <MenuItem value="all">Wszystkie</MenuItem>
                            {shops.map((shop) => (
                                <MenuItem key={shop.id} value={shop.id}>
                                    {shop.name}
                                </MenuItem>
                            ))}
                        </Select>
                    )}

                    <div className={classes.tabContent}>
                        <TableContainer component={Paper}>
                            <Table
                                className={classes.table}
                                aria-label="simple table"
                            >
                                <TableHead className={classes.tableHeader}>
                                    <TableRow hover>
                                        <TableCell align="center">
                                            KOD
                                        </TableCell>
                                        <TableCell align="center">
                                            MARKA
                                        </TableCell>
                                        <TableCell align="center">
                                            KOLOR
                                        </TableCell>
                                        <TableCell align="center">
                                            MODEL
                                        </TableCell>
                                        <TableCell align="center">
                                            ILOŚĆ SPRZEDAŻY
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {shoesData?.topFive ? (
                                        shoesData.topFive.map((shoe, index) => (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    {shoe.code}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {shoe.brand}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {shoe.color}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {shoe.type || 'N/A'}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {shoe.quantity}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell
                                                colSpan={4}
                                                align="center"
                                            >
                                                Brak danych do wyświetlenia
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {shoesData?.averageSale && (
                            <div className={classes.averageSaleContainer}>
                                <Typography variant="h6" align="left">
                                    Średnia wartość zamówienia:{' '}
                                    {Intl.NumberFormat('pl-PL', {
                                        style: 'currency',
                                        currency: 'PLN',
                                    }).format(shoesData.averageSale)}
                                </Typography>
                            </div>
                        )}
                    </div>
                </ViewContentCard>
            )}
        </>
    );
};

export default TopFiveModels;
