// @flow
import React from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    selectContainer: {
        minWidth: '160px',
        maxWidth: '300px',
        height: '100%',
        padding: '5px 16px',
    },
    menu: {
        zIndex: 9999,
    },
});

const customStyles = (classes) => ({
    container: (provided) => ({
        ...provided,
        width: '100%',
        height: '100%',
        minWidth: '200px',
    }),
    control: (provided) => ({
        ...provided,
        ...classes.control,
        boxShadow: 'none',
    }),
    menu: (provided) => ({
        ...provided,
        ...classes.menu,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
        color: 'black',
    }),
});

const SearchableUsersSelect = ({ users, data, setData }) => {
    const classes = useStyles();

    const options = users.map((user) => ({
        value: `${user.firstName} ${user.lastName}`,
        label: `${user.firstName} ${user.lastName}`,
    }));

    return (
        <div className={classes.selectContainer}>
            <Select
                options={options}
                value={options.find((opt) => opt.value === data.info)}
                onChange={(selectedOption) =>
                    setData({
                        ...data,
                        info: selectedOption ? selectedOption.value : '',
                    })
                }
                placeholder="Imię i nazwisko"
                isSearchable
                styles={customStyles(classes)}
                noOptionsMessage={() => 'Brak wyników'}
            />
        </div>
    );
};

export default SearchableUsersSelect;
