import React from 'react';
import { Card, Grid, makeStyles } from '@material-ui/core';
import {
    selectCurrencyRate,
} from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';
import * as text from 'constants/strings';
import { currencyCodeWithoutPLN } from 'constants/global';
import SaleCurrencyValueGridItem from './SaleCurrencyValueGridItemText';

const useStyles = makeStyles(() => ({
    currencyContainer: {
        maxWidth: 'calc(100vw - 49px)',
        padding: '10px 20px',
        marginBottom: '10px',
    },
}));

const SaleCurrencyValue = () => {
    const classes = useStyles();
    const currencyRate = useSelector((state) => selectCurrencyRate(state));
    const currencyRateCodes = Object.values(currencyCodeWithoutPLN);
    
    return (
        <Card className={classes.currencyContainer}>
            <Grid container spacing={8}>
                {currencyRateCodes.map((code) => (
                    <Grid item>
                        <SaleCurrencyValueGridItem 
                            currencyText={text.CURRENCY_RATE_TEXT[code]}
                            currencyRate={currencyRate[code]}
                        />
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

export default SaleCurrencyValue;