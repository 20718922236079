// @flow
import React, { useState } from 'react';
import {
    TableRow,
    TableCell,
    TextField,
    Button,
    Switch,
    Select,
    MenuItem,
} from '@material-ui/core';
import { formatPrice } from 'helpers/global';
import { concat, find, map, round } from 'lodash';
import * as text from 'constants/strings';
import SearchableUsersSelect from './SearchableUsersSelect';

type Props = {
    row: any,
    handleClick: Function,
    discounts: Array,
    hasPermissions: Boolean,
    users: Array,
};

const ReleaseTableRow = ({
    row,
    handleClick,
    discounts,
    hasPermissions,
    users,
}: Props) => {
    const [data, setData] = useState({ sizeTo: '', info: '', discount: null });
    const [checked, setChecked] = useState(!hasPermissions);
    const [rowData, setRowData] = useState(row);

    const getDiscountPrice = (priceToBeChanged, value) => {
        let price = row[priceToBeChanged];
        if (value === 0) {
            return row.promotional_price;
        }

        price -= price * (value / 100);

        return round(price, 0);
    };

    const handleChangeDiscount = (id = null, value = 0) => {
        const priceField =
            row.promotional_price > 0 ? 'promotional_price' : 'primary_price';
        const discountedPrice = getDiscountPrice(priceField, value);

        setRowData((prevData) => ({
            ...prevData,
            promotional_price: discountedPrice,
        }));
        setData((prevData) => ({
            ...prevData,
            discount: id,
        }));
    };

    const handleSubmitEnter = (e, rowData, data) => {
        if (e.key === 'Enter') {
            handleClick(rowData, data);
        }
    };

    return (
        <TableRow hover key={rowData.id}>
            <TableCell align="center">{rowData.code}</TableCell>
            <TableCell align="center">{rowData.brand}</TableCell>
            <TableCell align="center">{rowData.color}</TableCell>
            <TableCell align="center">{rowData.type}</TableCell>
            <TableCell align="center">
                {formatPrice(rowData.primary_price)}
            </TableCell>
            <TableCell align="center">
                {formatPrice(rowData.promotional_price)}
            </TableCell>
            {hasPermissions && (
                <TableCell align="center">
                    <Select
                        displayEmpty
                        title="Zniżka obuwie dla pracowników"
                        value={data?.discount || null}
                        onChange={(e) => {
                            const selectDiscount = find(discounts, {
                                id: e.target.value,
                            });
                            handleChangeDiscount(
                                selectDiscount?.id,
                                selectDiscount?.value
                            );
                        }}
                    >
                        <MenuItem value="" disabled>
                            {text.EMPLOYEE_EXCHANGE_DISCOUNT}
                        </MenuItem>
                        {discounts &&
                            discounts.length > 0 &&
                            map(
                                concat(
                                    { id: '', value: '', name: '-' },
                                    discounts
                                ),
                                (discount) => (
                                    <MenuItem
                                        key={discount.id}
                                        value={discount.id}
                                    >
                                        {discount.name}
                                    </MenuItem>
                                )
                            )}
                    </Select>
                </TableCell>
            )}
            <TableCell align="center">
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                        width: '100%',
                    }}
                >
                    {hasPermissions && !checked && (
                        <SearchableUsersSelect
                            users={users}
                            data={data}
                            setData={setData}
                        />
                    )}

                    {checked && (
                        <TextField
                            label={checked ? 'info' : 'imię nazwisko'}
                            value={data.info}
                            onChange={(e) =>
                                setData({ ...data, info: e.target.value })
                            }
                        />
                    )}

                    {hasPermissions && (
                        <Switch
                            checked={checked}
                            onChange={(e) => setChecked(e.target.checked)}
                            name="checkedA"
                        />
                    )}
                </div>
            </TableCell>

            <TableCell align="center">
                <TextField
                    label="rozmiar"
                    type="number"
                    value={data.sizeTo}
                    onKeyDown={(e) => handleSubmitEnter(e, rowData.id, data)}
                    onChange={(e) =>
                        setData({ ...data, sizeTo: e.target.value })
                    }
                />
            </TableCell>
            <TableCell align="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClick(rowData.id, data)}
                >
                    Wydaj
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ReleaseTableRow;
