import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    currencyValue: {
        fontWeight: 'bold',
    },
}));

const SaleCurrencyValueGridItemText = ({
    currencyText,
    currencyRate,
}) => {
    const classes = useStyles();

    return (
        <>
            {currencyText}
            <span className={classes.currencyValue}>
                {': '}
                {currencyRate}
                {' zł'}
            </span> 
        </>
    );
};

export default SaleCurrencyValueGridItemText;