import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import * as text from 'constants/strings';
import { Addition, Sort } from 'helpers/interface';
import React, { memo } from 'react';
import { StyledTableCell } from 'styles/components';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';
import TableLoader from 'components/TableLoader';
import { findColorShopById, formatNumber } from 'helpers/global';
import { useSelector } from 'react-redux';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';
import { map, size } from 'lodash';
import TableSortLabel from '@material-ui/core/TableSortLabel';

type EnhancedTableHeadProps = {
    onSelectAllClick: (arg: React.ChangeEvent<HTMLInputElement>) => void;
    numSelected: number;
    rowCount: number;
    setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
    sort: Sort | null;
};

function EnhancedTableHead({
    onSelectAllClick,
    numSelected,
    rowCount,
    sort,
    setSort,
}: EnhancedTableHeadProps) {
    return (
        <TableHead>
            <TableRow>
                <StyledTableCell padding="checkbox">
                    <Checkbox
                        indeterminate={
                            numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                    />
                </StyledTableCell>
                <StyledTableCell padding="none">{text.CODE}</StyledTableCell>
                <StyledTableCell padding="none">
                    <TableSortLabel
                        active={sort?.key === 'product_brand'}
                        direction={sort?.direction}
                        onClick={() => {
                            setSort(
                                sort?.key !== 'product_brand'
                                    ? {
                                          key: 'product_brand',
                                          direction: sort?.direction || 'asc',
                                      }
                                    : sort?.direction === 'desc'
                                    ? null
                                    : {
                                          key: 'product_brand',
                                          direction:
                                              sort === null ? 'asc' : 'desc',
                                      }
                            );
                        }}
                    >
                        {text.BRAND}
                    </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell padding="none">{text.COLOR}</StyledTableCell>
                <StyledTableCell padding="none">
                    <TableSortLabel
                        active={sort?.key === 'product_type'}
                        direction={sort?.direction}
                        onClick={() => {
                            setSort(
                                sort?.key !== 'product_type'
                                    ? {
                                          key: 'product_type',
                                          direction: sort?.direction || 'asc',
                                      }
                                    : sort?.direction === 'desc'
                                    ? null
                                    : {
                                          key: 'product_type',
                                          direction:
                                              sort === null ? 'asc' : 'desc',
                                      }
                            );
                        }}
                    >
                        {text.MODEL}
                    </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell
                    padding="none"
                    style={{ paddingRight: '10px', paddingLeft: '10px' }}
                >
                    {text.NORMAL_PRICE}
                </StyledTableCell>
                <StyledTableCell padding="none">
                    {text.PROMOTIONAL_PRICE}
                </StyledTableCell>
                <StyledTableCell style={{ paddingRight: '20px' }}>
                    {text.SIZE}
                </StyledTableCell>
                <StyledTableCell padding="none">
                    {text.QUANTITY}
                </StyledTableCell>
                <StyledTableCell padding="none">{text.SHOP}</StyledTableCell>
                <StyledTableCell padding="none">
                    {text.SHOP_STATE}
                </StyledTableCell>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    btn: {
        height: '40px',
    },
    btnGroup: {
        marginTop: '3px',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

type Props = {
    data: Addition[] | undefined;
    loading?: boolean;
    onRowClick: (
        event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
        row: Addition
    ) => void;
    selected: number[];
    onRemove?: () => void;
    setSelected: React.Dispatch<React.SetStateAction<number[]>>;
    setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
    sort: Sort | null;
};

function AdditionTable({
    data,
    loading = false,
    onRowClick,
    selected,
    onRemove,
    setSelected,
    setSort,
    sort,
}: Props) {
    const classes = useStyles();
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const handleSelectAllClick = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const newSelecteds = map(data, (n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    icon={<AddShoppingCartIcon />}
                    numSelected={selected.length}
                    handleClickRemoveSelected={onRemove}
                />
                <TableContainer style={{ position: 'relative' }}>
                    {loading && <TableLoader />}
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            numSelected={size(selected)}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={size(data)}
                            sort={sort}
                            setSort={setSort}
                        />
                        <TableBody>
                            {map(data, (row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        style={{
                                            backgroundColor: findColorShopById(
                                                shops,
                                                row.shop_to_id
                                            ),
                                        }}
                                        hover
                                        onClick={(e) => onRowClick(e, row)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.product_code}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.product_brand}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.product_color}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.product_type}
                                        </TableCell>
                                        <TableCell align="center">
                                            {`${formatNumber(
                                                row.product_primary_price
                                            )}zł`}
                                        </TableCell>
                                        <TableCell align="center">
                                            {`${formatNumber(
                                                row.product_promotional_price
                                            )}zł`}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.size}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.quantity}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.shop_to}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.shop_store || 0}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default memo(AdditionTable);
