import React, { useState, createContext, useEffect } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import { Button, Divider, makeStyles } from '@material-ui/core';
import * as text from 'constants/strings';
import 'moment/locale/pl';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import GraphicAttendanceScheduleDialog from 'components/Graphic/GraphicAttendanceScheduleDialog';
import GraphicAttendanceScheduleTable from 'components/Graphic/GraphicAttendanceScheduleTable';
import useIsGranted from 'hooks/useIsGranted';

moment.locale('pl');

export const AttendanceScheduleContext = createContext(null);

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    createSummaryDivider: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    thresholdButton: {
        marginRight: '20px',
        marginTop: '10px',
    },
}));

const GraphicShopAttendanceSchedule = () => {
    const isGranted = useIsGranted();
    const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
    const classes = useStyles();
    const [selectShop, setSelectShop] = useState('');
    const history = useHistory();
    const [startDate, setStartDate] = useState(moment().startOf('week'));
    const [update, setUpdate] = useState(null);
    const dispatch = useDispatch();
    const [shopScheduleData, setShopScheduleData] = useState(null);
    const [selectDate, setSelectDate] = useState(null);
    const [shopScheduleSheet, setShopScheduleSheet] = useState(null);
    const [selectQuantity, setSelectQuantity] = useState(null);
    const editAccess = isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_LIDER_CODE
    ]);
    const handleScheduleDialogOpen = (
        shopId,
        date = null,
        scheduleSheet = null
    ) => {
        setSelectShop(shopId);
        if (date !== null) {
            setSelectDate(date);
        }

        if (scheduleSheet !== null) {
            setShopScheduleSheet(scheduleSheet);
        }

        setScheduleDialogOpen(true);
    };

    const handleScheduleDialogClose = () => {
        setSelectShop(null);
        setScheduleDialogOpen(false);
        setShopScheduleSheet(null);
        setSelectDate(null);
        setSelectQuantity(null);
    };

    useEffect(() => {
        const getShopScheduleData = async () => {
            const dateFrom = moment(startDate)
                .startOf('week')
                .format('YYYY-MM-DD');
            const dateTo = moment(startDate).endOf('week').format('YYYY-MM-DD');

            const url = `/attendance-schedule?dateFrom=${dateFrom}&dateTo=${dateTo}`;

            dispatch(setIsLoading(true));
            try {
                const response = await sendAuthAjax('get', url);
                setShopScheduleData(response.data);
            } catch (error) {
                const errorText =
                    error.response.data.message || error.response.statusText;
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }
            dispatch(setIsLoading(false));
        };

        getShopScheduleData();
    }, [dispatch, startDate, update]);

    return (
        <>
            <ViewContentCard title={text.SHOP_ATTENDANCE_SCHEDULE}>
                <Button
                    onClick={() => history.push('/grafik-ustaw-progi')}
                    variant="contained"
                    color="primary"
                    className={classes.thresholdButton}
                >
                    {text.SET_THRESHOLDS}
                </Button>
                <Button
                    onClick={() => history.push('/grafik')}
                    variant="contained"
                    color="primary"
                    className={classes.thresholdButton}
                >
                    {text.SET_GRAPHIC}
                </Button>
                <Button
                    onClick={() => history.push('/grafik-generuj-podsumowanie')}
                    variant="contained"
                    color="primary"
                    className={classes.thresholdButton}
                >
                    {text.GENERATE_MONTHLY_SUMMARY}
                </Button>

                <Divider className={classes.createSummaryDivider} />
                <AttendanceScheduleContext.Provider
                    value={{
                        editAccess,
                        setScheduleDialogOpen,
                        scheduleDialogOpen,
                        startDate,
                        setStartDate,
                        setUpdate,
                        selectShop,
                        selectDate,
                        setSelectShop,
                        selectQuantity,
                        shopScheduleData,
                        shopScheduleSheet,
                        setSelectQuantity,
                        handleScheduleDialogOpen,
                        handleScheduleDialogClose,
                    }}
                >
                    <GraphicAttendanceScheduleDialog />
                    <GraphicAttendanceScheduleTable />
                </AttendanceScheduleContext.Provider>
            </ViewContentCard>
        </>
    );
};

export default GraphicShopAttendanceSchedule;
