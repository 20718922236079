//@ts-nocheck
import axios from 'axios';
import { currencySymbol, shopsAllowedForShopFromSelectInSaleTable } from 'constants/global';
import moment from 'moment';
import { Shop, Shoe } from './interface';
import {shopShortName} from "../constants/strings";
import React from "react";

export const getApiUrl = (): string => {
    if (process.env.NODE_ENV !== 'production') {
        return '';
    }

    return process.env.REACT_APP_API_BASE_URL || 'http://localhost:9999/api/v1';
};

export const sendAjax = (
    method: string,
    path: string,
    data: any,
    isPublic: boolean = false
) => {
    const apiUrl: string = getApiUrl();

    return axios({
        method,
        url: isPublic ? `${path}` : `${apiUrl}${path}`,
        data,
    });
};

export const convertToEuro = (
    price: number,
    euroRate: number,
    round: boolean = false
) => {
    const convertedPrice = euroRate > 0 ? price / euroRate : 0;

    return round
        ? Math.round(convertedPrice)
        : parseFloat(convertedPrice).toFixed(2);
};

export const convertToCurrency = (
    price: number,
    currencyRate: number,
    round: boolean = false
) => {
    if (!currencyRate) {
        return price;
    }

    const convertedPrice = currencyRate > 0 ? price / currencyRate : 0;

    return round
        ? Math.round(convertedPrice)
        : parseFloat(convertedPrice).toFixed(2);
};

export const formatNumber = (value: string | number) => {
    const numberValue = parseFloat(value);

    if (!Number.isInteger(numberValue)) {
        return numberValue.toFixed(2);
    }

    return numberValue.toFixed(0);
};

export const hasDuplicates = (data: array): boolean => {
    return new Set(data).size !== data.length;
};

export const validateNumberValue = (value: string) => {
    return /^[1,2,3,4,5,6,7,8,9]+\d*$/.test(value);
};

export const validateFloatValue = (value: string) => {
    return /^[+-]?\d+(\.\d+)?$/.test(value);
};

export const findShopById = (shops: Shop[], id: number) => {
    const shop = shops.find((shop: Shop) => shop.id === parseInt(id, 10));
    return shop;
};

export const findShopByName = (shops: Shop[], name: number) => {
    const shop = shops.find((shop: Shop) => shop.name === name);
    return shop;
};

export const findEcommerceShop = (shops: Shop[]) => {
    const shop = shops.find((shop: Shop) => shop.ecommerce);
    return shop;
};

export const findColorShopByName = (shops: Shop[], name: string) => {
    const shop = findShopByName(shops, name);
    const color = shop ? shop.color : '';
    return color;
};

export const findColorShopById = (shops: Shop[], id: number) => {
    const shop = findShopById(shops, id);
    const color = shop ? shop.color : '';
    return color;
};

export const findColorByShopNameKrupowki = (shops: Shop[], id: number) => {
    const shop = findShopById(shops, id);
    const color = shop.name === 'Krupówki' ? '#000' : shop.color;
    return color;
};

export const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : '';
};

export const formatDateInHour = (date) => {
    return date ? moment(date).format('YYYY-MM-DD HH:mm') : '';
};

export const formatName = (name) => {
    return name
        .split(' ')
        .map((el) => el.charAt(0).toUpperCase() + el.slice(1))
        .join(' ');
};

export const formatPrice = (_price: number) => {
    const price = Number(_price).toString();
    return !!price && price.length ? `${price} ${currencySymbol.PLN}` : '';
};

export const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const sortSizes = (sizes: array) => {
    return sizes.sort((a, b) => {
        if (a.size < b.size) {
            return -1;
        }
        if (a.size === b.size) {
            return 0;
        }

        return 1;
    });
};

export const trimStringAndReturnNullIfNoLength = (string: string) => {
    let trimmedString = string.trim();
    if (trimmedString.length > 0) return trimmedString;
    return null;
};

export const createShoeBlockHeader = (shoe: Shoe) => {
    return `${shoe.brand} ${shoe.code} ${shoe.color} ${shoe.type}`;
};

export const filterShopsWithoutEcommerce = (shops: Shop[], render: (shop: Shop) => React.Node) => {
    return shops.filter((shop: Shop) => shop.short !== shopShortName.ECOMMERCE).map(
        render
    )
}

export const isShopAllowedToShowShopFrom = (shop: Shop) => {
    if (shopsAllowedForShopFromSelectInSaleTable.includes(shop.name)) {
        return true;
    }
    return false;
}
