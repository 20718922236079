// @flow
import React, { useState, useEffect, useRef } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    makeStyles,
    IconButton,
    Grid,
    ButtonGroup,
} from '@material-ui/core';
import { AxiosError } from "axios";
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { paymentMethod, invoiceStatus, currencySymbol } from 'constants/global';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { useDispatch } from 'react-redux';
import { sendAuthAjax, sendAuthFetch } from 'helpers/authorization';
import SaleTable from 'components/SaleTable';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useHistory, NavLink } from 'react-router-dom';
import SearchBar from 'material-ui-search-bar';
import { formatDate, formatNumber } from 'helpers/global';
import Theme from "../styles/theme";
import type { CorrectiveNote, Invoice } from "../helpers/interface";
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles((theme: Theme) => ({
    addInvoice: {
        marginBottom: '10px',
    },
}));

const Invoices = () => {
    const [invoices, setInvoices] = useState([]);
    const [correctiveNotes, setCorrectiveNotes] = useState([]);
    const [phrase, setPhrase] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const ref = useRef(null);
    const isGranted = useIsGranted();

    const hasPermissions = isGranted.hasOneOfRoles.execute([text.ROLE_MANAGER_CODE, text.ROLE_ADMIN_CODE, text.ROLE_DIRECTOR_CODE, text.ROLE_LIDER_CODE]);

    const getInvoices = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/invoices`);
            setInvoices(response.data);

        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getCorrectiveNotes = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/corrective_notes`);
            setCorrectiveNotes(response.data);

        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getPdf = async (id: number) => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthFetch(`/invoices/${id}/pdf`);
            const blobby = await response.blob();
            const objectUrl = window.URL.createObjectURL(blobby);

            ref.current.href = objectUrl;
            ref.current.download = 'faktura.pdf';
            ref.current.click();

            window.URL.revokeObjectURL(objectUrl);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getCorrectiveNotePdf = async (id: number) => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthFetch(`/corrective_note_pdf/${id}`);
            const blobby = await response.blob();
            const objectUrl = window.URL.createObjectURL(blobby);

            ref.current.href = objectUrl;
            ref.current.download = 'nota_korygujaca.pdf';
            ref.current.click();

            window.URL.revokeObjectURL(objectUrl);
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleChangeSearch = (phrase) => {
        setPhrase(phrase);
    };

    const handleClickRemoveInvoice = async (id) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/invoices/${id}`);
            setInvoices(invoices.filter((inv: Invoice) => inv.id !== id));
            getInvoices();
            getCorrectiveNotes();
            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClickRemoveCorrectiveNote = async (id: number) => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('delete', `/corrective_note/${id}`);
            setCorrectiveNotes(correctiveNotes.filter((con: CorrectiveNote) => con.id !== id));
            getInvoices();
            getCorrectiveNotes();
            dispatch(
                addAlert({ content: 'Poprawnie usunięto', type: 'success' })
            );
        } catch (err) {
            const error: AxiosError = err;
            const errorText = error.response.data.message ?? error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getInvoices();
        getCorrectiveNotes();
    }, []);

    return (
        <>
            <ViewContentCard title={text.CORRECTIVE_NOTES}>
                {correctiveNotes.length > 0 && (
                    <SaleTable
                        headers={[
                            text.REMOVE,
                            text.NOTE_NUMBER,
                            text.INVOICE_NUMBER,
                            '',
                        ].filter((head: string) => hasPermissions || (!hasPermissions && head !== text.REMOVE))}
                        rowsData={correctiveNotes}
                        rowRender={(row: CorrectiveNote) => (
                            <TableRow hover key={row.id}>
                                <TableCell align="center">
                                    <IconButton
                                        onClick={() =>
                                            handleClickRemoveCorrectiveNote(row.id)
                                        }
                                    >
                                        <DeleteOutlineIcon color="error" />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">{row.noteNumber}</TableCell>
                                <TableCell align="center">{row.invoiceNumber}</TableCell>

                                <TableCell
                                    align="center"
                                >
                                    <ButtonGroup
                                        style={{ margin: '0px 5px' }}
                                        orientation="vertical"
                                        color="primary"
                                        aria-label="vertical outlined primary button group"
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => getCorrectiveNotePdf(row.id)}
                                        >
                                            PDF
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        )}
                    />
                )}
            </ViewContentCard>

            <ViewContentCard title={text.INVOICES}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={2}>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={() => history.push('/wystaw-fakture')}
                            className={classes.addInvoice}
                        >
                            Wystaw fakturę
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <SearchBar
                            style={{ height: 36, marginBottom: 10 }}
                            value={phrase}
                            onChange={(value) => handleChangeSearch(value)}
                            onRequestSearch={() => 0}
                            placeholder="Wyszukaj fakturę"
                            onCancelSearch={() => handleChangeSearch('')}
                        />
                    </Grid>
                </Grid>

                {invoices.length > 0 && (
                    <SaleTable
                        headers={[
                            text.REMOVE,
                            text.NUMBER,
                            text.COMPANY_NAME,
                            'NIP',
                            text.SHOP,
                            text.DATE_OF_ISSUE,
                            text.DATE_OF_PAYMENT,
                            text.DATE_OF_SALE,
                            text.PAYMENT_METHOD,
                            text.STATUS,
                            text.AMOUNT,
                            '',
                        ].filter((head: string) => hasPermissions || (!hasPermissions && head !== text.REMOVE))}
                        rowRender={(row: Invoice) => (
                            <TableRow hover key={row.id}>
                                {hasPermissions && (
                                    <TableCell align="center">
                                        {row.is_corrected < 1 &&
                                            <IconButton
                                                onClick={() =>
                                                    handleClickRemoveInvoice(row.id)
                                                }
                                            >
                                                <DeleteOutlineIcon color="error" />
                                            </IconButton>
                                        }
                                    </TableCell>
                                )}
                                <TableCell align="center">{row.number}</TableCell>
                                <TableCell align="center">
                                    {row.company_name || `${row.person_name}`}
                                </TableCell>
                                <TableCell align="center">{row.nip}</TableCell>
                                <TableCell align="center">
                                    {row.shop_name}
                                </TableCell>
                                <TableCell align="center">
                                    {formatDate(row.date_of_issue)}
                                </TableCell>
                                <TableCell align="center">
                                    {formatDate(row.date_of_payment)}
                                </TableCell>
                                <TableCell align="center">
                                    {formatDate(row.date_of_sale)}
                                </TableCell>
                                <TableCell align="center">
                                    {paymentMethod[row.payment_type]}
                                </TableCell>
                                <TableCell align="center">
                                    {invoiceStatus[row.status]}
                                </TableCell>
                                <TableCell align="center">
                                    {formatNumber(row.amount)}
                                    {currencySymbol[row.currency]}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={classes.btnGroup}
                                >
                                    <ButtonGroup
                                        style={{ margin: '0px 5px' }}
                                        orientation="vertical"
                                        color="primary"
                                        aria-label="vertical outlined primary button group"
                                        variant="outlined"
                                        size="small"
                                    >
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => getPdf(row.id)}
                                        >
                                            PDF
                                        </Button>
                                        {row.is_corrected < 1 && row.is_corrective < 1 &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                to={`/wystaw-fakture/${row.id}`}
                                                component={NavLink}
                                            >
                                                Edytuj
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        )}
                        rowsData={invoices.filter((inv) =>
                            inv.number.includes(phrase)
                        )}
                    />
                )}
                <a style={{ display: 'none' }} ref={ref} />
            </ViewContentCard>
        </>
    )};

export default Invoices;
