// @flow
import PrivateRoute from 'components/PrivateRoute';
import Addition from 'pages/Addition';
import AddShoes from 'pages/AddShoes';
import AddShortages from 'pages/AddShortages';
import AddSizes from 'pages/AddSizes';
import AlertsHistory from 'pages/AlertsHistory';
import Complaint from 'pages/Complaint';
import Complaints from 'pages/Complaints';
import Dictionaries from 'pages/Dictionaries';
import EditShoes from 'pages/EditShoes ';
import EditWorker from 'pages/EditWorker';
import Errors from 'pages/Errors';
import ExchangeShoes from 'pages/ExchangeShoes';
import Exhibition from 'pages/Exhibition';
import AttendanceList from 'pages/AttendanceList';
import FindShoes from 'pages/FindShoes';
import Graphic from 'pages/Graphic';
import Invoice from 'pages/Invoice';
import Invoices from 'pages/Invoices';
import Labels from 'pages/Labels';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import Logs from 'pages/Logs';
import Messages from 'pages/Messages';
import Coupons from 'pages/Coupons';
import NoMatch from 'pages/NoMatch';
import OrdersPreview from 'pages/OrdersPreview';
import Warehouse from 'pages/Warehouse';
import PriceChange from 'pages/PriceChange';
import ReleaseShoes from 'pages/ReleaseShoes';
import Reports from 'pages/Reports';
import Sale from 'pages/Sale';
import Shortages from 'pages/Shortages';
import StatementAddition from 'pages/StatementAddition';
import StatementExchange from 'pages/StatementExchange';
import StatementProduct from 'pages/StatementProduct';
import StatementProductDetail from 'pages/StatementProductDetail';
import StatementShop from 'pages/StatementShop';
import StatementTotal from 'pages/StatementTotal';
import TransferShoes from 'pages/TransferShoes';
import TransferShoesFrom from 'pages/TransferShoesFrom';
import TransferShoesTo from 'pages/TransferShoesTo';
import Workers from 'pages/Workers';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as text from 'constants/strings';
import Settings from 'pages/Settings';
import ShoperConfig from 'pages/ShoperConfig';
import ProductOfTheDay from 'pages/ProductOfTheDay';
import useIsGranted from 'hooks/useIsGranted';
import GraphicMonthlySummary from 'pages/GraphicMonthlySummary';
import GraphicThreshold from 'pages/GraphicThreshold';
import Checklist from './pages/Checklist';
import ChecklistCreate, {
    Modes as checklistCreateModes,
} from './pages/ChecklistCreate';
import ShopAttendanceSchedule from './pages/GraphicAttendanceSchedule';

const App = () => {
    const isGranted = useIsGranted();

    return (
        <Switch>
            <Route exact path="/" visible>
                <Login />
            </Route>
            <Route exact path="/logout" visible>
                <Logout />
            </Route>
            <PrivateRoute
                exact
                path="/sprzedaz"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
                shopRequired
            >
                <Sale />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/znajdz-buty"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <FindShoes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/przerzut"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
                shopRequired
            >
                <TransferShoesTo />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/przerzut-butow/:fromId/:toId"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
                shopRequired
            >
                <TransferShoes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/podglad-sprzedazy"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <OrdersPreview />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/magazyn"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Warehouse />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/wymiana-butow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
                shopRequired
            >
                <ExchangeShoes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/wydanie"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
                // shopRequired
            >
                <ReleaseShoes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/reklamacje"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Complaints />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/zmiana-ceny"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <PriceChange />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/komunikaty"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Messages />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/dodanie-butow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
            >
                <AddShoes />
            </PrivateRoute>
            <PrivateRoute exact path="/lista-obecnosci" visible>
                <AttendanceList />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/bledy/:id"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <EditShoes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/dodanie-rozmiarow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
            >
                <AddSizes />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/braki"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Shortages />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/braki/dodaj"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <AddShortages />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/bledy"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Errors />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/duze-przerzuty"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
                shopRequired
            >
                <TransferShoesFrom />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/grafik"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Graphic />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/pracownicy"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <Workers />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/pracownicy/:type/:id?"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <EditWorker />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/faktury"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Invoices />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/wystaw-fakture/:id?"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Invoice />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/dodaj-reklamacje/:id?"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Complaint />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/logi"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <Logs />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/historia-komunikatow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <AlertsHistory />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/slowniki"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <Dictionaries />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/calkowite-zestawienie-sprzedazy"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <StatementTotal />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/historia-poszczegolnego-modelu"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <StatementProduct />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/historia-poszczegolnego-modelu/:id"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <StatementProductDetail />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/obuwie-wydane-dla-pracownikow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <StatementExchange />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/podglad-dodan"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <StatementAddition />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/zestawienie-sprzedazy-z-poszczegolnych-sklepow"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <StatementShop />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/dolozenie/:localization"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_WAREHOUSE_WORKER_CODE,
                    text.ROLE_WAREHOUSE_DIRECTOR_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                ])}
            >
                <Addition />
            </PrivateRoute>
            <PrivateRoute exact path="/raporty" visible>
                <Reports />
            </PrivateRoute>
            <PrivateRoute exact path="/wystawa" visible>
                <Exhibition />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/etykiety"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                ])}
            >
                <Labels />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/produkt-dnia"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_EMPLOYEE_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <ProductOfTheDay />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/checklista"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Checklist />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/checklista/wypelnij"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <ChecklistCreate mode={checklistCreateModes.add} />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/checklista/wypelnij/:id"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <ChecklistCreate mode={checklistCreateModes.edit} />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/checklista/pokaz/:id"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <ChecklistCreate mode={checklistCreateModes.show} />
            </PrivateRoute>
            <PrivateRoute exact path="/ustawienia" visible>
                <Settings />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/shoper-konfiguracja"
                visible={isGranted.canSeeShoperConfiguration.execute()}
            >
                <ShoperConfig />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/grafik-generuj-podsumowanie"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <GraphicMonthlySummary />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/grafik-ustaw-progi"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <GraphicThreshold />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/grafik/harmonogram"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <ShopAttendanceSchedule />
            </PrivateRoute>
            <PrivateRoute
                exact
                path="/kupony"
                visible={isGranted.hasOneOfRoles.execute([
                    text.ROLE_MANAGER_CODE,
                    text.ROLE_ADMIN_CODE,
                    text.ROLE_DIRECTOR_CODE,
                    text.ROLE_LIDER_CODE,
                ])}
            >
                <Coupons />
            </PrivateRoute>
            <Route path="*" visible>
                <NoMatch />
            </Route>
        </Switch>
    );
};

export default App;
