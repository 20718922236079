// @flow
import React, { useEffect, useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import SearchBar from 'components/SearchBar';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { sendAuthAjax } from 'helpers/authorization';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReleaseTableRow from 'components/ReleaseTableRow';
import { selectUser } from 'context/features/user/userSlice';
import { validateNumberValue } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import { clearSearchParams } from 'context/features/sale/saleSlice';
import { Select, MenuItem, makeStyles, InputLabel } from '@material-ui/core';
import { selectShopsWithoutWarehouse } from 'context/features/global/globalSlice';

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: '8.5rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('xs')]: {
            maxHeight: '11rem',
        },
    },
    tabContent: {
        padding: theme.spacing(2),
    },
    tableHeader: (props) => ({
        backgroundColor: props.headerColor,
    }),
    shopSelect: {
        marginLeft: '1rem',
    },
    averageSaleContainer: {
        marginTop: '.5rem',
    },
}));

const ReleaseShoes = () => {
    const [result, setResult] = useState([]);
    const [users, setUsers] = useState([]);
    const dispatch = useDispatch();
    const { releaseId } = useParams();
    const user = useSelector((state) => selectUser(state));
    const [discounts, setDiscounts] = useState([]);
    const isGranted = useIsGranted();
    const hasPermissions = isGranted.hasOneOfRoles.execute([
        text.ROLE_DIRECTOR_CODE,
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_LIDER_CODE,
    ]);

    const hasPermissionsWithoutAssignedShop = isGranted.hasOneOfRoles.execute([
        text.ROLE_LIDER_CODE,
        text.ROLE_ADMIN_CODE,
    ]);

    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));

    const [selectedShop, setSelectedShop] = useState();

    const getDiscounts = async () => {
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax(
                'get',
                `/employee-exchange-discounts`
            );

            setDiscounts(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const getUsers = async () => {
        if (!hasPermissions) return;
        dispatch(setIsLoading(true));
        try {
            const response = await sendAuthAjax('get', `/users`);

            setUsers(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const handleClick = async (productId: number, data: any) => {
        if (hasPermissionsWithoutAssignedShop && !selectedShop) {
            dispatch(
                addAlert({ content: 'Musisz wybrać sklep', type: 'error' })
            );
            return;
        }
        const { sizeTo, info, discount } = data;
        if (!validateNumberValue(sizeTo)) {
            dispatch(
                addAlert({ content: 'Wybierz poprawny rozmiar', type: 'error' })
            );
            return;
        }
        if (!info) {
            dispatch(
                addAlert({
                    content: 'Wypełnij pole z informacją',
                    type: 'error',
                })
            );
            return;
        }

        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/exchange/product-employee-release`, {
                sizeTo,
                info,
                productId,
                shopId: hasPermissionsWithoutAssignedShop
                    ? selectedShop
                    : user.shopId,
                discount,
            });
            dispatch(clearSearchParams());
            setResult([]);
            dispatch(addAlert({ content: 'Wydano obuwie', type: 'success' }));
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    const selectedShopData =
        selectedShop === 'all'
            ? { name: 'Wszystkie sklepy', color: '#ffffff' }
            : shops.find((shop) => shop.id === selectedShop);

    const classes = useStyles({
        headerColor: selectedShopData?.color || '#ffffff',
    });
    const handleShopChange = (event) => {
        setSelectedShop(event.target.value);
    };

    const headers = releaseId
        ? [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              text.SIZE,
              '',
          ]
        : [
              text.CODE,
              text.BRAND,
              text.COLOR,
              text.MODEL,
              text.NORMAL_PRICE,
              text.PROMOTIONAL_PRICE,
              ...(hasPermissions ? [text.EMPLOYEE_EXCHANGE_DISCOUNT] : []),
              text.INFO,
              text.SIZE,
              '',
          ];

    useEffect(() => {
        getDiscounts();
        getUsers();
    }, []);

    return (
        <ViewContentCard title={text.SHOE_RELEASE}>
            {hasPermissionsWithoutAssignedShop && (
                <div>
                    Wybierz sklep
                    <Select
                        value={selectedShop}
                        onChange={handleShopChange}
                        displayEmpty
                        className={classes.shopSelect}
                        label="Wybierz sklep"
                        id="demo-simple-select"
                    >
                        {shops.map((shop) => (
                            <MenuItem key={shop.id} value={shop.id}>
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            )}
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={headers}
                    rowRender={(row) => (
                        <ReleaseTableRow
                            key={row.id}
                            row={row}
                            handleClick={handleClick}
                            discounts={discounts}
                            hasPermissions={hasPermissions}
                            users={users}
                        />
                    )}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default ReleaseShoes;
