// @flow
import React, { useState } from 'react';
import ViewContentCard from 'components/Layout/ViewContentCard';
import * as text from 'constants/strings';
import { sendAuthAjax } from 'helpers/authorization';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'context/features/user/userSlice';
import {
    selectShops,
    addAlert,
    setIsLoading,
} from 'context/features/global/globalSlice';
import SearchBar from 'components/SearchBar';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import {
    TableRow,
    TableCell,
    Button,
    MenuItem,
    Select,
    makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useIsGranted from 'hooks/useIsGranted';

const useStyles = makeStyles(() => ({
    shopsSelect: {
        minWidth: '115px',
    },
}));

const Row = ({ row }: any) => {
    const user = useSelector((state) => selectUser(state));
    const classes = useStyles();
    const shops = useSelector((state) => selectShops(state));
    const isGranted = useIsGranted();
    const disabled = !isGranted.hasOneOfRoles.execute([
        text.ROLE_MANAGER_CODE,
        text.ROLE_ADMIN_CODE,
        text.ROLE_LIDER_CODE,
    ]);
    const dispatch = useDispatch();
    const [data, setData] = useState({
        shopId: user.shopId || shops[0].id,
        productId: row.id,
    });
    const history = useHistory();

    const handleClickAdd = async () => {
        dispatch(setIsLoading(true));
        try {
            await sendAuthAjax('post', `/shortages`, data);
            dispatch(
                addAlert({ content: 'Poprawnie dodano', type: 'success' })
            );
            history.push('/braki');
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    return (
        <TableRow hover key={row.id}>
            <TableCell align="center">{row.code}</TableCell>
            <TableCell align="center">{row.brand}</TableCell>
            <TableCell align="center">{row.color}</TableCell>
            <TableCell align="center">{row.type}</TableCell>
            <TableCell align="center">
                <Select
                    disabled={disabled}
                    className={classes.shopsSelect}
                    value={data.shopId}
                    onChange={(e) =>
                        setData({ ...data, shopId: e.target.value })
                    }
                >
                    {shops.map((shop) => (
                        <MenuItem key={shop.id} value={shop.id}>
                            {shop.name}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="center" style={{ width: '10%' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleClickAdd}
                >
                    Dodaj!
                </Button>
            </TableCell>
        </TableRow>
    );
};

const AddShortages = () => {
    const [result, setResult] = useState([]);

    return (
        <ViewContentCard title={text.ADDING_SHORTAGES}>
            <SearchBar setResult={setResult} />
            {result.length > 0 && (
                <SaleTableWithPagination
                    headers={[
                        text.CODE,
                        text.BRAND,
                        text.COLOR,
                        text.MODEL,
                        text.SHOP,
                        '',
                    ]}
                    rowRender={(row) => <Row key={row.id} row={row} />}
                    rowsData={result}
                />
            )}
        </ViewContentCard>
    );
};

export default AddShortages;
