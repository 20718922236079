// @flow
import React, { useEffect, useState } from 'react';
import {
    TableRow,
    TableCell,
    makeStyles,
    Button,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@material-ui/core';
import ViewContentCard from 'components/Layout/ViewContentCard';
import SearchBar from 'components/SearchBar';
import * as text from 'constants/strings';
import { useHistory } from 'react-router-dom';
import SaleTableWithPagination from 'components/SaleTableWithPagination';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import { sendAuthAjax } from 'helpers/authorization';
import { setIsLoading, addAlert } from 'context/features/global/globalSlice';
import { selectSaleSearch } from 'context/features/sale/saleSlice';
import { formatPrice } from 'helpers/global';
import useIsGranted from 'hooks/useIsGranted';
import EnhancedTableToolbar from 'components/EnhancedTableToolbar';

const useStyles = makeStyles((theme) => ({
    addToDatabase: {
        margin: theme.spacing(1, 0, 0, 0),
    },
}));

const Errors = () => {
    const classes = useStyles();
    const [result, setResult] = useState([]);
    const history = useHistory();
    const [selected, setSelected] = useState([]);
    const [notInStock, setNotInStock] = useState(false);
    const dispatch = useDispatch();
    const saleSearch = useSelector((state) => selectSaleSearch(state));
    const isGranted = useIsGranted();
    const isAdminOrManager = isGranted.hasOneOfRoles.execute([
        text.ROLE_ADMIN_CODE,
        text.ROLE_MANAGER_CODE,
    ]);

    const handleClickRowSelect = (id: number) => {
        history.push(`/bledy/${id}`);
    };

    const handleClickSelect = (event, id) => {
        if (!isAdminOrManager) {
            return;
        }

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleClickRemoveSelected = async () => {
        if (!isAdminOrManager) {
            return;
        }

        if (window.confirm('Czy napewno chcesz usunąć dany model?')) {
            if (selected.length < 1) return;
            dispatch(setIsLoading(true));

            try {
                await sendAuthAjax('delete', '/products', {
                    productsId: selected,
                });

                setResult((prev) => {
                    return prev.filter((item) => !selected.includes(item.id));
                });
                setSelected([]);
                dispatch(
                    addAlert({ content: 'Poprawnie usunięto', type: 'success' })
                );
            } catch (error) {
                const errorText = error.response.data.message
                    ? error.response.data.message
                    : error.response.statusText;
                dispatch(addAlert({ content: errorText, type: 'error' }));
            }
            dispatch(setIsLoading(false));
        }
    };

    const getNotInStockProducts = async () => {
        dispatch(setIsLoading(true));

        try {
            const response = await sendAuthAjax(
                'post',
                `/products/search/shoes`,
                { ...saleSearch, notInStock, onlyInStock: false }
            );

            setResult(response.data);
        } catch (error) {
            const errorText = error.response.data.message
                ? error.response.data.message
                : error.response.statusText;
            dispatch(addAlert({ content: errorText, type: 'error' }));
        }
        dispatch(setIsLoading(false));
    };

    useEffect(() => {
        getNotInStockProducts();
    }, [notInStock]);

    return (
        <ViewContentCard title={text.ERRORS}>
            <SearchBar addParams={{ notInStock }} setResult={setResult} />
            <FormControlLabel
                control={
                    <Switch
                        checked={notInStock}
                        onChange={(e) => setNotInStock(e.target.checked)}
                    />
                }
                label="Produkty które nie występują na stanie"
            />
            {result.length > 0 && (
                <>
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        handleClickRemoveSelected={handleClickRemoveSelected}
                        icon={<DeleteIcon />}
                    />
                    <SaleTableWithPagination
                        headers={[
                            '-',
                            text.CODE,
                            text.BRAND,
                            text.COLOR,
                            text.MODEL,
                            text.NORMAL_PRICE,
                            text.PROMOTIONAL_PRICE,
                            '',
                        ]}
                        rowRender={(row, index) => {
                            const isItemSelected = isSelected(row.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow
                                    hover
                                    onClick={(event) =>
                                        handleClickSelect(event, row.id)
                                    }
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    key={row.id}
                                    selected={isItemSelected}
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{
                                                'aria-labelledby': labelId,
                                            }}
                                            disabled={!isAdminOrManager}
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.code}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.brand}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.color}
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.type}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatPrice(row.primary_price)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatPrice(row.promotional_price)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                handleClickRowSelect(row.id)
                                            }
                                        >
                                            Popraw
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        }}
                        rowsData={result}
                    />
                </>
            )}
        </ViewContentCard>
    );
};

export default Errors;
