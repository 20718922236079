// @flow
/* eslint-disable no-param-reassign */
import { createSlice, createSelector } from '@reduxjs/toolkit';

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        isLoading: false,
        alert: null,
        shops: [],
        allRoles: [],
        messageCounter: 0,
        currencyRate: {}
    },
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        addAlert: (state, action) => {
            state.alert = {
                content: action.payload.content,
                type: action.payload.type,
            };
        },
        setShops: (state, action) => {
            state.shops = action.payload.shops;
        },
        setAllRoles: (state, action) => {
            state.allRoles = action.payload;
        },
        removeAlert: (state) => {
            state.alert = null;
        },
        setMessageCounter: (state, action) => {
            state.messageCounter = action.payload;
        },
        removeMessageCounter: (state) => {
            state.messageCounter -= 1;
        },
        setCurrencyRate: (state, action) => {
            state.currencyRate = action.payload;
        }
    },
});

export const selectGlobal = createSelector(
    (state) => state.global,
    (state) => state
);

export const selectMessageCounter = createSelector(
    (state) => state.global.messageCounter,
    (state) => state
);

export const selectShops = createSelector(
    (state) => state.global.shops,
    (state) => state
);

export const selectCurrencyRate = createSelector(
    (state) => state.global.currencyRate,
    (state) => state
);

export const selectShop = (shopId) =>
    createSelector(
        (state) => state.global.shops,
        (shops) => shops.find((s) => s.id === parseInt(shopId, 10))
    );

export const selectShopsWithoutWarehouse = createSelector(
    (state) => state.global.shops,
    (state) => state.filter((shop) => shop.name !== 'Magazyn')
);

export const selectAllRoles = createSelector(
    (state) => state.global.allRoles,
    (state) => state
);

export const {
    setIsLoading,
    addAlert,
    removeAlert,
    setShops,
    setAllRoles,
    setMessageCounter,
    removeMessageCounter,
    setEuroRate,
    setDollarRate,
    setPoundRate,
    setCrownRate,
    setCurrencyRate,
} = globalSlice.actions;

export default globalSlice.reducer;
