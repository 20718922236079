// @flow
import React, { useState, memo } from 'react';
import {
    Button,
    TextField,
    TableRow,
    TableCell,
    Select,
    MenuItem,
    Box,
    makeStyles,
} from '@material-ui/core';
import {
    selectShopsWithoutWarehouse,
    selectCurrencyRate,
    addAlert,
    setIsLoading,
} from 'context/features/global/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    currencyCode,
    currencySymbol,
    shopsAllowedForShopFromSelectInSaleTable,
} from 'constants/global';
import green from '@material-ui/core/colors/green';
import clsx from 'clsx';
import { convertToCurrency } from 'helpers/global';
import { selectUser } from 'context/features/user/userSlice';
import { sendAuthAjax } from 'helpers/authorization';
import * as text from 'constants/strings';
import { concat, filter, find, includes, isEqual, map } from 'lodash';
import type { Order, Shoe } from '../helpers/interface';

type Props = {
    rowData: Array<string>,
    currencyVisible: boolean,
    shopFromVisible: boolean,
    handleClickRow: (data: Order, productData: Shoe) => React.Node,
};

const useStyles = makeStyles(() => ({
    discount: {
        width: '65px',
        marginRight: '10px',
    },
    featured: {
        color: green.A400,
    },
    shopsSelect: {
        minWidth: '115px',
    },
}));

const SaleTableRow = ({
    rowData,
    viewType,
    currencyVisible = true,
    shopFromVisible = false,
    showCouponSelect = false,
    handleClickRow,
}: Props) => {
    const classes = useStyles();
    const currencyRate = useSelector((state) => selectCurrencyRate(state));
    const shops = useSelector((state) => selectShopsWithoutWarehouse(state));
    const user = useSelector((state) => selectUser(state));
    const currencyCodes = Object.values(currencyCode);
    const [coupons, setCoupons] = useState([]);
    const [currentCurrencySymbol, setCurrentCurrencySymbol] = useState('');
    const dispatch = useDispatch();
    const [isCouponsFetched, setIsCouponsFetched] = useState(false);
    const [data, setData] = useState({
        primary_price: rowData.primary_price,
        promotional_price: rowData.promotional_price,
        currency: currencyCode.PLN,
        discount: {
            percent: '',
            currValue: '',
        },
        currencyValue: rowData.promotional_price > 0
            ? rowData.promotional_price
            : rowData.primary_price,
        size: '',
        quantity: '',
        coupon: null,
        shopFromId: user.shopId || null,
    });

    const fetchCoupons = async (type, price) => {
        if (!isCouponsFetched) {
            dispatch(setIsLoading(true));

            try {
                const response = await sendAuthAjax(
                    'get',
                    `/coupons/sale?type=${type}&price=${price}`
                );
                setCoupons(response.data);
                setIsCouponsFetched(true);
            } catch (error) {
                const errorText =
                    error.response?.data?.message ||
                    error.response?.statusText ||
                    'Napotkano nieznany błąd';
                dispatch(addAlert({ content: errorText, type: 'error' }));
            } finally {
                dispatch(setIsLoading(false));
            }
        }
    };

    const getDiscountPrice = (priceToBeChanged, value, type) => {
        let price = rowData[priceToBeChanged];

        if (value === '') {
            return price;
        }

        if (type === 'percent') {
            price -= price * (value / 100);
        } else {
            price -= value;
        }

        return Math.round(Number.isInteger(price) ? price : price.toFixed(0));
    };

    const handleChangeDiscount = (value: number, type: string) => {
        const priceToBeChanged: string =
            rowData.promotional_price > 0
                ? 'promotional_price'
                : 'primary_price';

        const price = getDiscountPrice(priceToBeChanged, value, type);
        const currencyValue = convertToCurrency(price, currencyRate[data.currency], true);

        setData((prevData) => ({
            ...prevData,
            [priceToBeChanged]: price,
            currencyValue,
            discount: {
                ...prevData.discount,
                [type]: value,
            },
        }));
    };

    const handleCouponSelected = (couponId, couponValue) => {
        const coupon = couponId ? { id: couponId, value: couponValue } : null;
        const couponValueTemp = couponValue ? Math.trunc(couponValue) : '';

        handleChangeDiscount(couponValueTemp, 'percent');
        setData((prevData) => ({
            ...prevData,
            coupon: coupon,
        }));
    };

    const convertValueToCurrrency = (value) => {
        return convertToCurrency(
            rowData.promotional_price > 0
                ? rowData.promotional_price
                : rowData.primary_price,
            currencyRate[value],
            true
        );
    }

    const changeCurrencySymbol = (currency: string) => {
        return currency !== currencyCode.PLN ? currencySymbol[currency] : null;
    } 

    const handleChangeCurrency = (value: string) => {
        setData({
            ...data,
            currencyValue: convertValueToCurrrency(value),
            primary_price: rowData.primary_price,
            promotional_price: rowData.promotional_price,
            currency: value,
            discount: {
                percent: '',
                currValue: '',
            },
            coupon: null,
        });

        setCurrentCurrencySymbol(changeCurrencySymbol(value));
    };

    const handleSubmitEnter = (e, data, rowData) => {
        if (e.key === 'Enter') {
            handleClickRow(data, rowData);
        }
    };

    const shopsInShopFromSelect = filter(shops, (shop: Shop) =>
        includes(shopsAllowedForShopFromSelectInSaleTable, shop.name)
    );

    return (
        <TableRow hover>
            <TableCell align="center">{rowData.code}</TableCell>
            <TableCell align="center">{rowData.brand}</TableCell>
            <TableCell align="center">{rowData.color}</TableCell>
            <TableCell align="center">{rowData.type}</TableCell>
            <TableCell align="center">
                {`${data.primary_price} ${currencySymbol[currencyCode.PLN]}`}
            </TableCell>
            <TableCell align="center">
                {`${data.promotional_price} ${currencySymbol[currencyCode.PLN]}`}
            </TableCell>
            {currencyVisible && (
                <TableCell align="center" className={classes.featured}>
                    {data.currency !== currencyCode.PLN && data.currencyValue}
                    &nbsp;
                    {currentCurrencySymbol}
                </TableCell>
            )}
            <TableCell align="center">
                <Box display="flex" width="150" justifyContent="center">
                    <TextField
                        value={data.discount.percent}
                        className={classes.discount}
                        placeholder="%"
                        type="number"
                        disabled={!!data.discount.currValue || !!data.coupon}
                        onChange={(e) =>
                            handleChangeDiscount(e.target.value, 'percent')
                        }
                    />
                    <TextField
                        value={data.discount.currValue}
                        className={classes.discount}
                        placeholder={currencySymbol[currencyCode.PLN]}
                        type="number"
                        disabled={!!data.discount.percent || !!data.coupon}
                        onChange={(e) =>
                            handleChangeDiscount(e.target.value, 'currValue')
                        }
                    />
                </Box>
            </TableCell>
            {showCouponSelect && (
                <TableCell align="center">
                    <Select
                        onFocus={() =>
                            fetchCoupons(
                                rowData.type,
                                rowData.promotional_price > 0
                                    ? rowData.promotional_price
                                    : rowData.primary_price
                            )
                        }
                        title={text.CHECK_COUPONS}
                        value={data.coupon?.id || ''}
                        onChange={(e) => {
                            const selectedCoupon = find(
                                coupons,
                                (coupon) => coupon.id === e.target.value
                            );
                            handleCouponSelected(
                                selectedCoupon?.id,
                                selectedCoupon?.value
                            );
                        }}
                    >
                        {coupons && coupons.length > 0 ? (
                            map(
                                concat({ id: '', code: '-' }, coupons),
                                (coupon) => (
                                    <MenuItem key={coupon.id} value={coupon.id}>
                                        {coupon.code}
                                    </MenuItem>
                                )
                            )
                        ) : (
                            <MenuItem value="" disabled>
                                {isCouponsFetched
                                    ? text.NOT_COUPONS_AVAILABLE_FOR_PRODUCT
                                    : text.CHECK_COUPONS}
                            </MenuItem>
                        )}
                    </Select>
                </TableCell>
            )}
            {currencyVisible && (
                <TableCell align="center">
                    <Select
                        value={data.currency}
                        onChange={(e) => handleChangeCurrency(e.target.value)}
                    >
                        {currencyCodes.map((code) => (
                            <MenuItem value={currencyCode[code]}>{currencyCode[code]}</MenuItem>
                        ))}
                    </Select>
                </TableCell>
            )}
            {shopFromVisible && (
                <TableCell align="center">
                    <Select
                        value={data.shopFromId}
                        className={clsx(
                            data.shopFromId === user.shopId && classes.featured,
                            classes.shopsSelect
                        )}
                        onChange={(e) =>
                            setData({ ...data, shopFromId: e.target.value })
                        }
                    >
                        {map(shopsInShopFromSelect, (shop) => (
                            <MenuItem
                                className={clsx(
                                    user.shopId === shop.id && classes.featured
                                )}
                                key={shop.id}
                                value={shop.id}
                            >
                                {shop.name}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
            )}
            <TableCell align="right">
                <TextField
                    placeholder="rozmiar"
                    type="number"
                    onKeyDown={(e) => handleSubmitEnter(e, data, rowData)}
                    onChange={(e) => setData({ ...data, size: e.target.value })}
                    value={data.size}
                />
            </TableCell>
            {viewType === 'SALE_OF_SOCKS_AND_INSOLES' && (
                <TableCell align="right">
                    <TextField
                        placeholder="ilość"
                        type="number"
                        onChange={(e) =>
                            setData({ ...data, quantity: e.target.value })
                        }
                        value={data.quantity}
                    />
                </TableCell>
            )}

            <TableCell align="right">
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => handleClickRow(data, rowData)}
                >
                    {currencyVisible ? 'Sprzedano!' : 'Dodaj'}
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo<Props>(SaleTableRow, (prev: Order, next: Order) =>
    isEqual(prev, next)
);
