// @flow
import React, { useContext, useEffect } from 'react';
import {
    TableRow,
    TableCell,
    Button,
    makeStyles,
    IconButton,
    Paper,
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    Grid,
} from '@material-ui/core';
import * as text from 'constants/strings';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { AddingInvoiceContext } from 'pages/Invoice';
import { StyledTableCell } from 'styles/components';
import { currencySymbol, currencyCode } from 'constants/global';
import { formatNumber, findShopById, convertToCurrency } from 'helpers/global';
import { selectCurrencyRate } from 'context/features/global/globalSlice';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    addProduct: {
        margin: `${theme.spacing(1)}px 0px`,
    },
    table: {
        margin: theme.spacing(1),
    },
}));

const headers = [
    text.REMOVE,
    text.CODE,
    text.BRAND,
    text.COLOR,
    text.MODEL,
    text.SIZE,
    text.PRICE,
];

const InvoiceOrdersBlock = () => {
    const {
        setInvoiceDialogOpen,
        orders,
        setOrders,
        invoiceData,
        setInvoiceData,
    } = useContext(AddingInvoiceContext);
    const classes = useStyles();
    const currencyRate = useSelector((state) => selectCurrencyRate(state));

    const getPrice = (row) => {
        let price =
            row.promotional_price > 0
                ? row.promotional_price
                : row.primary_price;

        if (invoiceData.currency !== currencyCode.PLN && row.currency_price > 0) {
            price = row.currency_price;
        } else if (invoiceData.currency !== currencyCode.PLN && !row.currency_price) {
            price = convertToCurrency(price, currencyRate[invoiceData.currency], true);
        }

        return `${formatNumber(price)} ${currencySymbol[invoiceData.currency]}`;
    };

    const setAmount = () => {
        const sum = orders.reduce((p, c) => {
            const price =
                c.promotional_price > 0 ? c.promotional_price : c.primary_price;
            const { currency_price } = c;

            return invoiceData.currency !== currencyCode.PLN
                ? p + parseFloat(currency_price)
                : p + parseFloat(price);
        }, 0);
        setInvoiceData((prev) => {
            return {
                ...prev,
                amount: sum,
            };
        });
    };

    useEffect(() => {
        setAmount();
    }, [orders, invoiceData.currency]);

    return (
        <Grid container>
            <Grid item xs={12} md={2}>
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={() => setInvoiceDialogOpen(true)}
                    className={classes.addProduct}
                >
                    Dodaj produkt
                </Button>
            </Grid>
            <Grid item xs={12}>
                {orders.length > 0 && (
                    <TableContainer component={Paper} className={classes.table}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    {headers.map((head) => (
                                        <StyledTableCell key={`head-${head}`}>
                                            {head}
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map((row) => (
                                    <TableRow hover key={row.id}>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() =>
                                                    setOrders((prev) =>
                                                        prev.filter(
                                                            (item) =>
                                                                item.id !==
                                                                row.id
                                                        )
                                                    )
                                                }
                                            >
                                                <DeleteOutlineIcon color="error" />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.code}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.brand}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.color}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.type}
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.size}
                                        </TableCell>
                                        <TableCell align="center">
                                            {getPrice(row)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={5} />
                                    <TableCell align="center">Suma</TableCell>
                                    <TableCell align="center">
                                        {`
                                    ${formatNumber(invoiceData.amount)}
                                    ${currencySymbol[invoiceData.currency]}
                                    `}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
};

export default InvoiceOrdersBlock;
